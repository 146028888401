import React from "react";
import "./AboutUs.css";

export class AboutUs extends React.Component {
  render() {
    return (
      <section>
        <div className="container">
          <div className="row innerpage-title">
            <div className="col-md-12">
              <h1 className="event-head">About Us</h1>
            </div>
            {/* <div className="col-md-6 searchBox mb20 text-right">
								<form>
									<input type="search" placeholder="Search Categories" />
								</form>
							</div> */}
          </div>
          <div className="sect-container">
            <div className=" gap-y pdt30 pdb20">
              <div className="col-md-12">
                <p>
                  BetDeEx is a decentralized prediction platform, where you all
                  can win Era Swap Rewards just by predicting with accuracy on
                  multi-category trending events. On BetDeEx you do not have to
                  worry about huge cuts which other platforms charge on your
                  winning claims, unlike other centralized and unauthorized
                  centralized platforms BetDeEx does not charge you multi-layer
                  hefty fees.{" "}
                </p>
                <p>
                  Apart from BetDeEx, Era Swap has initiated multiple
                  decentralized platforms for day to day life utilities. With a
                  decentralized approach not only it tackles unnecessary
                  interference of middlemen but also offers efficiency,
                  reliability, and faster transactions, globally
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
