import React, { Component } from "react";
import { ethers } from "ethers";
import { logo } from "../../components/Images/Image";
import { RouteComponentProps } from "react-router-dom";
import { BetFactory } from "../../ehtereum/Typechain/BetFactory";
import { Bet } from "../../ehtereum/Typechain/Bet";
import "./EventDetail.css";
import { AllCategory } from "../../utils/AllCategories";
import { fetchData, fetchResult, fetchYes } from "./utils";
import Swal from "sweetalert2";
import Loader from "../Loader";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import CommentBox from "./CommentBox";

interface NewPredictEvent {
  category: number;
  deployer: string;
  description: string;
  subCategory: number;
  contractAddress: string;
}

interface BetDetail {
  category: number;
  subcategory: number;
  name: string;
  description: string;
  minimumBet: number;
  prize: number;
  startTime: Date;
  pauseTime: Date;
  TotalPrize: number;
  Incentive: number;
  Amount: number[];
}

interface BetResult {
  isResult: boolean;
  endTime: Date;
  endBy: string;
  RewardX: number;
  result: number;
}

type Props = {};

type State = {
  choice: number;
  betToken: number;
  spinner: boolean;
  details: BetDetail;
  Results: BetResult;
  Yes: any[];
  No: any[];
  Draw: any[];
  approved: boolean;
  Full: string;
};

interface MatchParams {
  contractAddress: string;
}

export class EventDetail extends Component<
  RouteComponentProps<MatchParams>,
  State
> {
  //@ts-ignore
  betInstance: Bet;

  constructor(props: RouteComponentProps<MatchParams>) {
    super(props);
    this.state = {
      choice: 0,
      betToken: 0,
      spinner: false,
      details: {
        category: 0,
        subcategory: 0,
        name: "",
        description: "",
        minimumBet: 0,
        prize: 0,
        startTime: new Date(),
        pauseTime: new Date(),
        TotalPrize: 0,
        Incentive: 0,
        Amount: [0, 0, 0],
      },
      Results: {
        isResult: false,
        endTime: new Date(),
        endBy: " ",
        RewardX: 0,
        result: 0,
      },
      Yes: [],
      No: [],
      Draw: [],
      approved: false,
      Full: "",
    };
  }
  async componentDidMount() {
    this.setState({ spinner: true });
    const x: boolean = await window.BetDeExInst.isBetValid(
      this.props.match.params.contractAddress
    );
    this.betInstance = BetFactory.connect(
      this.props.match.params.contractAddress,
      window.providerESN
    );
    await this.fetchNewBetEvent();
    const FullDescripton = await axios(
      `https://ipfs.eraswap.cloud/ipfs/${this.state.details.description}`
    );
    console.log(FullDescripton.data);

    this.setState({ spinner: false, approved: x, Full: FullDescripton.data });
  }

  enterBet = async () => {
    if (window.wallet) {
      const A = await this.betInstance
        .connect(window.wallet)
        .populateTransaction.enterBet(this.state.choice, {
          value: ethers.utils.parseEther(this.state.betToken.toString()),
        });
      console.log("call : ", A);
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this action!",
        html: `<p>You will not be able to undo this action!</p>
										<h1 style={{fontStyle:"bold"}}> Value : ${
                      A.value ? ethers.utils.formatEther(A?.value) : "0"
                    } </h1>
										<small> To : ${A.to} </small><br/><small> From : ${A.from} ES </small>
										<p> gasFee : ${A?.gasPrice || "0"} </p>`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, cancel!",
        confirmButtonText: "Yes, do it!",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.betInstance
            .connect(window.wallet)
            .enterBet(this.state.choice, {
              value: ethers.utils.parseEther(this.state.betToken.toString()),
            })
            .then(async (res) => {
              await res.wait();
              Swal.fire({
                title: "Good job!",
                icon: "success",
              });
            })
            .catch(async () => {
              const add = window.wallet.address
                ? window.wallet.address
                : await window.wallet.getAddress();
              const x = new ethers.VoidSigner(add, window.providerESN);
              try {
                await this.betInstance
                  .connect(x)
                  .estimateGas.enterBet(this.state.choice, {
                    value: ethers.utils.parseEther(
                      this.state.betToken.toString()
                    ),
                  });
              } catch (e) {
                console.log("Error is : ", e);
                Swal.fire("Oops...!", `${e}`, "error");
              }
            });
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Please Connect to wallet ...",
      });
    }

    // this.setState({ spinner: false })
  };

  withDrawAmount = async () => {
    if (window.wallet) {
      const address = window.wallet.address
        ? window.wallet.address
        : await window.wallet.getAddress();
      const A = await this.betInstance
        .connect(window.wallet)
        .populateTransaction.withdrawPrize(address);
      console.log("call : ", A);
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this action!",
        html: `<p>You will not be able to undo this action!</p>
										<h1 style={{fontStyle:"bold"}}> Value : ${
                      A.value ? ethers.utils.formatEther(A?.value) : "0"
                    } </h1>
										<small> To : ${A.to} </small><br/><small> From : ${A.from} ES </small>
										<p> gasFee : ${A?.gasPrice || "0"} </p>`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, cancel!",
        confirmButtonText: "Yes, do it!",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.betInstance
            .connect(window.wallet)
            .withdrawPrize(address)
            .then(async (res) => {
              await res.wait();
              Swal.fire({
                title: "Good job!",
                icon: "success",
              });
            })
            .catch(async () => {
              const add = window.wallet.address
                ? window.wallet.address
                : await window.wallet.getAddress();
              const x = new ethers.VoidSigner(add, window.providerESN);
              try {
                await this.betInstance
                  .connect(x)
                  .estimateGas.withdrawPrize(address);
              } catch (e) {
                console.log("Error is : ", e);
                Swal.fire("Oops...!", `${e}`, "error");
              }
            });
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Please Connect to wallet ...",
      });
    }

    // this.setState({ spinner: false })
  };

  allCategories = new AllCategory();

  async fetchNewBetEvent() {
    const address = this.props.match.params.contractAddress;
    console.log(address);

    window.BetInst = BetFactory.connect(address, window.providerESN);

    const x = await fetchData(address);
    const y = await fetchResult(address);
    const yes = await fetchYes(address, 0);
    const no = await fetchYes(address, 1);
    const draw = await fetchYes(address, 2);
    console.log(yes);

    this.setState({ details: x });
    this.setState({ Results: y });
    this.setState({ Yes: yes, No: no, Draw: draw });
    console.log("done");
  }

  closeModal = () => {
    this.setState({
      betToken: 0,
      choice: 0,
    });
  };
  resultType = [
    <div className="text-success">
      <i className="fa fa-check-circle" aria-hidden="true"></i> Yes
    </div>,
    <div className="text-danger">
      {" "}
      <i className="fas fa-cross"></i> No
    </div>,
    <div className="text-warning">Draw</div>,
  ];

  render() {
    return (
      <section>
        {this.state.spinner ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="row innerpage-title">
              {/* <div className="col-md-12">
						<h1 className="event-head">Predict </h1>
					</div> */}
              <div className="col-md-6">
                <b>Bet Address : </b>
                {this.props.match.params.contractAddress}
              </div>
            </div>
            {this.state.approved ? (
              <div className="badge bg-success text-light">
                <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                Approved{" "}
              </div>
            ) : (
              <div className="badge bg-danger text-light">
                <i className="fas fa-cross"></i> Not Approved Yet
              </div>
            )}
            <div className="sect-container">
              <div className="row pd-box p-3">
                <div className="row">
                  <div className="col-md-3">
                    <div className="left-ev-head">
                      <div className="left-block">
                        <p className="ev-txt">Total Volume</p>
                        <p className="bd-lines">
                          {this.state.details.TotalPrize} ES
                        </p>
                      </div>
                      <div className="left-block">
                        <p className="ev-txt">Creater Fee</p>
                        <p className="bd-lines">
                          {100 - this.state.details.prize} %
                        </p>
                      </div>
                      <div className="left-block">
                        <p className="ev-txt">Incentive from Creator's Fee</p>
                        <p className="bd-lines">
                          {this.state.details.Incentive} %{" "}
                        </p>
                      </div>
                      <div className="left-block">
                        <p className="ev-txt">Min ES for prediction </p>
                        <p className="bd-lines">
                          {this.state.details.minimumBet} ES
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="wd-event">
                      <div className="main-ev-desc">
                        <div className="catg-ev-one">
                          <p>
                            CATEGORY /{" "}
                            <span className="bd-lines">
                              {
                                this.allCategories.categoryArray[
                                  this.state.details.category
                                ]
                              }
                            </span>
                          </p>
                          <p>
                            {" "}
                            /
                            <span className="bd-lines">
                              {
                                this.allCategories.subCategoryArray[
                                  this.state.details.category
                                ][this.state.details.subcategory]
                              }
                            </span>
                          </p>
                        </div>
                        <div className="catg-ev-two">
                          <p>
                            Pause Time{" "}
                            <span className="bd-lines">
                              {this.state.details.pauseTime.toString()}
                            </span>
                          </p>
                        </div>
                      </div>
                      <h3 className="ev-title">{this.state.details.name}</h3>
                      <div className="row ">
                        <div
                          className="col-lg-4 "
                          style={{
                            textAlign: "center",
                            overflowWrap: "break-word",
                          }}
                        >
                          <h4>
                            <p style={{ textAlign: "center" }}>
                              <strong id="user-globe-rank">
                                {this.state.details.Amount[0]}ES{" "}
                              </strong>
                            </p>
                          </h4>
                          <p>
                            <small className="badge bg-success">
                              Yes Amount
                            </small>
                          </p>
                        </div>
                        <div
                          className=" col-lg-4 "
                          style={{
                            textAlign: "center",
                            overflowWrap: "break-word",
                          }}
                        >
                          <h4>
                            <p style={{ textAlign: "center" }}>
                              <strong id="user-college-rank">
                                {this.state.details.Amount[1]} ES{" "}
                              </strong>
                            </p>
                          </h4>
                          <p>
                            {" "}
                            <small className="badge bg-danger text-light">
                              No Amount
                            </small>
                          </p>
                        </div>
                        <div
                          className=" col-lg-4 "
                          style={{
                            textAlign: "center",
                            overflowWrap: "break-word",
                          }}
                        >
                          <h4>
                            <p style={{ textAlign: "center" }}>
                              <strong id="user-college-rank">
                                {this.state.details.Amount[2]} ES
                              </strong>
                            </p>
                          </h4>
                          <p>
                            {" "}
                            <small className="badge bg-warning">
                              Draw Amount
                            </small>
                          </p>
                        </div>
                      </div>
                      <div className="row left-ev-head mt20">
                        <button
                          type="button"
                          className="btn btn-success"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          ENTER PREDICTION
                        </button>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
                <ReactMarkdown source={this.state.Full} escapeHtml={false} />

                {/* <!-- Modal --> */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <img
                          className="btdex-logo-modal"
                          alt="logo"
                          src={logo}
                        />
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true" className="close-style">
                            &times;
                          </span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div>
                          <form>
                            <label className="md-lb-txt">
                              Prediction Choice:
                            </label>
                            <select
                              className="custom-select select-style"
                              placeholder="Select Prediction"
                              onChange={(event) =>
                                this.setState({
                                  choice: Number(event.target.value),
                                })
                              }
                            >
                              <option></option>
                              <option value={0}>YES</option>
                              <option value={1}>NO</option>
                              <option value={2}>DRAW</option>
                            </select>
                            <div className="form-group">
                              <label className="md-lb-txt">
                                Prediction Token:
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="exampleInputEmail1"
                                onChange={(event) =>
                                  this.setState({
                                    betToken: Number(event.target.value),
                                  })
                                }
                                placeholder="Enter Token"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={this.closeModal}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={this.enterBet}
                        >
                          {this.state.spinner ? "Saving..." : "Save Prediction"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal end here */}

                <div className="clear"></div>
                {this.state.Results.isResult ? (
                  <div className="card text-center mt30">
                    <div className="card-header">Results Announced 🎖🎖🎖</div>
                    <div className="card-body">
                      Correct Prediction is
                      <h5 className="card-title">
                        {this.resultType[this.state.Results.result]}
                      </h5>
                      Winner Reward is{" "}
                      <h3 className="text-success">
                        {this.state.Results.RewardX}x
                      </h3>
                      <p className="card-text">
                        This event's results has been declared Winners are
                        requested to withdraw their winnings from Participated
                        Events in their account pages.
                      </p>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={this.withDrawAmount}
                      >
                        WithDraw Now
                      </button>
                    </div>
                    <div className="card-footer text-muted">
                      Result is Declared by : {this.state.Results.endBy} on{" "}
                      {this.state.Results.endTime.toString()}
                    </div>
                  </div>
                ) : null}

                <CommentBox address={this.props.match.params.contractAddress} />

                <div className="col-md-12 mt30">
                  <ul
                    className="nav nav-pills mb-3 tab-com"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active text-white"
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        YES
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link text-white"
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        NO
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link text-white"
                        id="pills-contact-tab"
                        data-toggle="pill"
                        href="#pills-contact"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        DRAW
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>ADDRESS</th>
                              <th>AMOUNT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.Yes.map((e) => (
                              <tr>
                                <td>{e.address}</td>
                                <td>{e.amount}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="text-center text-primary">
                          {" "}
                          No More Prediction here
                        </div>{" "}
                        <hr />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>ADDRESS</th>
                              <th>AMOUNT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.No.map((e) => (
                              <tr>
                                <td>{e.address}</td>
                                <td>{e.amount}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="text-center text-primary">
                          {" "}
                          No More Prediction here
                        </div>{" "}
                        <hr />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-contact"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab"
                    >
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>SR</th>
                              <th>ADDRESS</th>
                              <th>AMOUNT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {" "}
                            {this.state.Draw.map((e) => (
                              <tr>
                                <td>{e.address}</td>
                                <td>{e.amount}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="text-center text-primary">
                          {" "}
                          No More Prediction here
                        </div>{" "}
                        <hr />
                      </div>
                    </div>
                  </div>
                  <p>
                    On BetDeEx, you can use your skills to leverage the power of
                    data and previous statistics to predict the outcome of
                    events. All guaranteed Era Swap are stored in smart contract
                    which ensures transparent & fair distribution of Era Swap to
                    winning group. Era Swap Token is subject to Market Risk.
                    Please read Era Swap Utility Token Whitepaper, Era Swap Life
                    T & C* before participating
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}
