import React, { Component } from "react";
import { logo } from "../Images/Image";
import "./Navbar.css";
import WalletContext from "../../utils/WalletContext";
// import { Navbar, Nav, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import copy from "copy-to-clipboard";
import ConnectWallet from "./ConnectWallet";

type State = {
  addressCopied: boolean;
  walletAddress: string | null;
};

export class NavbarMenu extends Component {
  // const context = useContext(ThemeContext);
  static contextType = WalletContext;

  state: State = {
    addressCopied: false,
    walletAddress: null,
  };

  componentDidMount() {
    this.updateWalletStatus.bind(this);
  }

  updateWalletStatus = async () => {
    // const isWalletLoaded = !!window.wallet;

    const currentWalletAddress: string | null = window.wallet
      ? (await window.wallet?.getAddress()) ?? window.wallet.address
      : null;

    if (currentWalletAddress !== this.state.walletAddress) {
      this.setState({ walletAddress: currentWalletAddress });
    }
  };

  openEraswapLife() {
    window.open(
      "https://eraswap.life/",
      "SignUp/Login",
      "width=1000,height=650"
    );
  }

  signOut() {
    window.location.href = "/";
  }

  copyAddress = () => {
    if (window.wallet.address && copy(window.wallet.address)) {
      this.setState({ addressCopied: true });
      setTimeout(() => this.setState({ addressCopied: false }), 2000);
    }
  };
  render() {
    console.log("check login", this.context.wallet);
    return (
      <header id="header" className="header-inner-pages">
        <div className="nav-two-container">
          <ul className="nav-top-flex">
            <li className="bd-lines tm-spc">
              CURRENT TIME: 17/08/2020 ,12:37:13
            </li>
            <div className="grn-bd"></div>
            <li className="bd-lines tm-spc">
              ES PRICE: 0.0941 USDT / 0.0000000809 BTC
            </li>
            <div className="grn-bd"></div>
            <li className="bd-lines tm-spc">GAS PRICE:0 ESMETER </li>
          </ul>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link to="/" className="navbar-brand">
            {" "}
            <img className="btdex-logo" alt="logo" src={logo} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <Link className="nav-link" to="/">
                  Home <span className="sr-only">(current)</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/results-previous-events">
                  Results
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about-us">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/faq">
                  Faq's
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/admin">
                  Admin
                </Link>
              </li>

              <li className="nav-item dropdown mb10">
                <Link
                  to="/event"
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Events
                </Link>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/event">
                    All Events
                  </Link>
                  <Link className="dropdown-item" to="/create-pre-event">
                    Create Event
                  </Link>
                </div>
              </li>
            </ul>
            <ul className="navbar-nav navbar-right profile-btn">
              <ConnectWallet />
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}
