import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "./lib/Theme";
import { NavbarMenu } from "./components/Navbar";
import { Footer } from "./components/Footer";

import { Homepage } from "./containers/Homepage";
import { GlobalStyles } from "./lib/Theme/GlobalStyles";
import { Event } from "./containers/Events/Event";
import { AllEvent } from "./containers/Events/AllEvent";
import { Faq } from "./containers/Faq/Faq";
import { AboutUs } from "./containers/AboutUs/AboutUs";
import { CreatePredictionEvents } from "./containers/CreatePredictionEvents/CreatePredictionEvents";
// import {PastEvents} from './containers/PastEvents/PastEvents';
import { ResultsPreviousEvents } from "./containers/ResultsPreviousEvents/ResultsPreviousEvents";
// import {UserProfile} from './containers/UserProfile/UserProfile';
// import {UserProfileUpdateAllowance} from './containers/UserProfileUpdateAllowance/UserProfileUpdateAllowance';
// import {HistoryPredictionTransaction} from './containers/HistoryPredictionTransaction/HistoryPredictionTransaction';
import { EventDetail } from "./containers/EventDetail/EventDetail";
//Additional
import { ethers } from "ethers";

//ADMIN
import { Admin } from "./containers/Admin/Admin";

declare global {
  interface Window {
    wallet: any;
  }
}

// window.wallet = new ethers.Wallet(
//   '0x4da9725b7ccfdd241f96110a4ba7ec5d1e2a55f230cdd3093ea003bb3928fa42'
// );

type State = {
  wallet: ethers.Wallet | null;
};

type Props = {};

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      wallet: null,
    };
  }

  // setWallet = (privateKey: string) => {
  //   this.setState({ wallet: new CustomWallet(privateKey) });
  // };

  //@ts-ignore
  componentDidMount() {
    // if (process.env.REACT_APP_NODE_ENV === 'development') {
    //   this.setWallet(window.wallet.privateKey);
    // }
    setTimeout(() => {
      try {
        window.onload = function () {
          window?.opener?.postMessage("loaded", "*");
        };
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }

  render() {
    console.log("setwallet", this.state.wallet);
    return (
      <div className="App">
        <ThemeProvider>
          <GlobalStyles />

          <Router>
            <NavbarMenu />
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route
                exact
                path="/event-detail/:contractAddress"
                component={EventDetail}
              />
              <Route exact path="/event" component={AllEvent} />
              <Route exact path="/event/:category" component={Event} />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/faq" component={Faq} />
              <Route
                exact
                path="/create-pre-event"
                component={CreatePredictionEvents}
              />
              <Route exact path="/admin" component={Admin} />
              {/* <Route exact path="/past-events" component={PastEvents} />
            <Route exact path="/user-profile" component={UserProfile} />
            <Route exact path="/user-profile-update-allowance" component={UserProfileUpdateAllowance} /> */}
              <Route
                exact
                path="/results-previous-events"
                component={ResultsPreviousEvents}
              />

              {/* <Route exact path="/historypredictiontransaction" component={HistoryPredictionTransaction} /> */}
            </Switch>
          </Router>

          <Footer />
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
