import { ethers } from "ethers";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { logo } from "../../components/Images/Image";
import { BetFactory } from "../../ehtereum/Typechain/BetFactory";

export default function AdminActions(props: { address: string }) {
  const [state, setState] = useState({ choice: 0, spinner: false });
  const betInstance = BetFactory.connect(props.address, window.providerESN);
  const endBet = async () => {
    if (window.wallet) {
      const A = await betInstance
        .connect(window.wallet)
        .populateTransaction.endBet(state.choice);
      console.log("call : ", A);
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this action!",
        html: `<p>You will not be able to undo this action!</p>
										<h1 style={{fontStyle:"bold"}}> Value : ${
                      A.value ? ethers.utils.formatEther(A?.value) : "0"
                    } </h1>
										<small> To : ${A.to} </small><br/><small> From : ${A.from} ES </small>
										<p> gasFee : ${A?.gasPrice || "0"} </p>`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, cancel!",
        confirmButtonText: "Yes, do it!",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return betInstance
            .connect(window.wallet)
            .endBet(state.choice)
            .then(async (res) => {
              await res.wait();
              Swal.fire({
                title: "Good job!",
                icon: "success",
              });
            })
            .catch(async () => {
              const add = window.wallet.address
                ? window.wallet.address
                : await window.wallet.getAddress();
              const x = new ethers.VoidSigner(add, window.providerESN);
              try {
                await betInstance.connect(x).estimateGas.endBet(state.choice);
              } catch (e) {
                console.log("Error is : ", e);
                Swal.fire("Oops...!", `${e}`, "error");
              }
            });
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Please Connect to wallet ...",
      });
    }
  };

  const approveBet = async () => {
    if (window.wallet) {
      const A = await window.BetDeExInst.connect(
        window.wallet
      ).populateTransaction.approveBet(props.address);
      console.log("call : ", A);
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this action!",
        html: `<p>You will not be able to undo this action!</p>
										<h1 style={{fontStyle:"bold"}}> Value : ${
                      A.value ? ethers.utils.formatEther(A?.value) : "0"
                    } </h1>
										<small> To : ${A.to} </small><br/><small> From : ${A.from} ES </small>
										<p> gasFee : ${A?.gasPrice || "0"} </p>`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, cancel!",
        confirmButtonText: "Yes, do it!",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return window.BetDeExInst.connect(window.wallet)
            .approveBet(props.address)
            .then(async (res) => {
              await res.wait();
              Swal.fire({
                title: "Good job!",
                icon: "success",
              });
            })
            .catch(async () => {
              const add = window.wallet.address
                ? window.wallet.address
                : await window.wallet.getAddress();
              const x = new ethers.VoidSigner(add, window.providerESN);
              try {
                await window.BetDeExInst.connect(x).estimateGas.approveBet(
                  props.address
                );
              } catch (e) {
                console.log("Error is : ", e);
                Swal.fire("Oops...!", `${e}`, "error");
              }
            });
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Please Connect to wallet ...",
      });
    }
  };

  const rejectBet = async () => {
    if (window.wallet) {
      const A = await betInstance
        .connect(window.wallet)
        .populateTransaction.rejectBet();
      console.log("call : ", A);
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this action!",
        html: `<p>You will not be able to undo this action!</p>
										<h1 style={{fontStyle:"bold"}}> Value : ${
                      A.value ? ethers.utils.formatEther(A?.value) : "0"
                    } </h1>
										<small> To : ${A.to} </small><br/><small> From : ${A.from} ES </small>
										<p> gasFee : ${A?.gasPrice || "0"} </p>`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, cancel!",
        confirmButtonText: "Yes, do it!",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return betInstance
            .connect(window.wallet)
            .rejectBet()
            .then(async (res) => {
              await res.wait();
              Swal.fire({
                title: "Good job!",
                icon: "success",
              });
            })
            .catch(async () => {
              const add = window.wallet.address
                ? window.wallet.address
                : await window.wallet.getAddress();
              const x = new ethers.VoidSigner(add, window.providerESN);
              try {
                await betInstance.connect(x).estimateGas.rejectBet();
              } catch (e) {
                console.log("Error is : ", e);
                Swal.fire("Oops...!", `${e}`, "error");
              }
            });
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Please Connect to wallet ...",
      });
    }
  };
  return (
    <div>
      <button type="button" className="btn btn-success" onClick={approveBet}>
        Approve Event
      </button>
      <button type="button" className="btn btn-danger" onClick={rejectBet}>
        Reject PREDICTION
      </button>
      <button
        type="button"
        className="btn btn-info"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        END PREDICTION
      </button>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <img className="btdex-logo-modal" alt="logo" src={logo} />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="close-style">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <form>
                  <label className="md-lb-txt">Prediction Choice:</label>
                  <select
                    className="custom-select select-style"
                    placeholder="Select Prediction"
                    onChange={(event) =>
                      setState({ ...state, choice: Number(event.target.value) })
                    }
                  >
                    <option></option>
                    <option value={0}>YES</option>
                    <option value={1}>NO</option>
                    <option value={2}>DRAW</option>
                  </select>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={endBet}
              >
                {state.spinner ? "Ending..." : "End Prediction"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
