/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./homepage.css";
import {
  logo,
  c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  c8,
  eraIcon,
  absone,
  betdeex3,
  betdeex2,
  betdex1,
} from "../../components/Images/Image";
import { AllCategory } from "../../utils/AllCategories";

type Props = {};

type State = {
  betName: string;
  category: string;
  subCategory: string;
  description: string;
  minBet: number | null;
  pricePerThousand: number | null;
  isDraw: boolean | null;
  pauseTimestamp: Date | null;
};

export class Homepage extends React.Component {
  constructor(props: Props) {
    super(props);
    this.categoryInstance = new AllCategory();
  }
  state: State = {
    betName: "",
    category: "",
    subCategory: "",
    description: "",
    minBet: 50,
    pricePerThousand: 998,
    isDraw: false,
    pauseTimestamp: null,
  };

  categoryInstance: AllCategory;

  render() {
    return (
      <section>
        {/* hero section */}

        <header
          className="header online-payment-header section color-1 alpha-9"
          style={{
            backgroundSize: "cover",
          }}
        >
          {/* <div id="stripes"></div> */}
          {/* <img src="/img/Bdx_web-02.jpg"></img> */}
          <div
            className="container overflow-hidden bring-to-front banner"
            style={{ paddingBottom: 0 }}
          >
            <div className="row">
              <div
                className="col-lg-6 mx-auto text-center text-lg-left banner-text"
                style={{ margin: "50px" }}
              >
                <h1 className="light display-4 color-1">
                  Welcome to the{" "}
                  <span
                    className="bold d-block"
                    style={{
                      fontSize: "48px",
                      color: "#fff",
                      lineHeight: "44px",
                    }}
                  >
                    Future of Prediction
                  </span>
                </h1>
                <p
                  className="color-1 lead alpha-8 my-5"
                  style={{ fontSize: "18px" }}
                >
                  "Luck Becomecontainer bring-to-fronts Limitless Opportunity
                  with Confident Preparation <br></br>
                  <b className="decent-txt">
                    Decentralized, Transparent & Trustless
                  </b>
                  {/* <br> No Login, No Hassle, No Limits.*/}
                </p>
                <a
                  href="/event"
                  className="btn btn-rounded btn-lg btn-accent text-center ml-0 my-3"
                >
                  {" "}
                  Explore Events now
                </a>
                <a
                  href="/create-pre-event"
                  className="btn btn-rounded btn-lg btn-accent text-center ml-0 my-3"
                >
                  {" "}
                  CREATE PREDICTION
                </a>
              </div>
              <div className="col-lg-6 col-md-9 mx-md-auto mx-lg-0 pr-lg-0 pc">
                <img
                  className="img-responsive img-fluid bet-logo"
                  src={logo}
                  alt="Dashcore"
                />
                <b className="decnt-txt2">Powered by Era Swap</b>
              </div>
            </div>
          </div>
        </header>

        <section>
          <div className="lp spe-bot-red-3 mt60 mb60">
            <div className="inn-title">
              <h1 className="sect-head">TOP CATEGORY</h1>
              <h4 style={{ textTransform: "uppercase", textAlign: "center" }}>
                <i className="fa fa-check" aria-hidden="true" /> All predictions{" "}
                <img width="43" alt="icon " src={eraIcon} />
                <span>events 2020</span>
              </h4>
              <p className="txt-align">
                An 'event' is a prediction event. Now, you can choose an event
                from the list of multiple events
              </p>
            </div>
            <div className="hom-top-trends row mrg mt60">
              {/*TOP TRENDINGS*/}
              <div className="col-md-3">
                <a href="/event/:category">
                  <div className="hom-trend">
                    <div className="hom-trend-img">
                      <img className="img-responsive" src={c1} alt="" />
                    </div>
                    <div className="hom-trend-con inn-title">
                      {/* <span><i className="fa fa-futbol-o" aria-hidden="true" /> 2rd augest 2017</span> */}

                      <h2
                        className="txt-effect"
                        style={{ textTransform: "uppercase", fontSize: "16px" }}
                      >
                        SPORTS
                      </h2>

                      {/* <p>The Sports Games also celebrated and showcased sport, thanks to the city’s stunning setting</p> */}
                    </div>
                  </div>
                </a>
              </div>
              {/*TOP TRENDINGS*/}
              <div className="col-md-3">
                <a href="/event/:category">
                  <div className="hom-trend">
                    <div className="hom-trend-img">
                      <img className="img-responsive" src={c2} alt="" />
                    </div>
                    <div className="hom-trend-con">
                      {/* <span><i className="fa fa-futbol-o" aria-hidden="true" /> 2rd augest 2017</span> */}

                      <h2
                        className="txt-effect"
                        style={{ textTransform: "uppercase", fontSize: "16px" }}
                      >
                        MOVIES
                      </h2>
                      {/* <p>The Sports Games also celebrated and showcased sport, thanks to the city’s stunning setting</p> */}
                    </div>
                  </div>
                </a>
              </div>
              {/*TOP TRENDINGS*/}
              <div className="col-md-3">
                <a href="/event/:category">
                  <div className="hom-trend">
                    <div className="hom-trend-img">
                      <img className="img-responsive" src={c3} alt="" />
                    </div>
                    <div className="hom-trend-con">
                      {/* <span><i className="fa fa-futbol-o" aria-hidden="true" /> 2rd augest 2017</span> */}

                      <h2
                        className="txt-effect"
                        style={{ textTransform: "uppercase", fontSize: "16px" }}
                      >
                        POLITICS
                      </h2>

                      {/* <p>The Sports Games also celebrated and showcased sport, thanks to the city’s stunning setting</p> */}
                    </div>
                  </div>
                </a>
              </div>
              {/*TOP TRENDINGS*/}
              <div className="col-md-3">
                <a href="/event/:category">
                  <div className="hom-trend">
                    <div className="hom-trend-img">
                      <img className="img-responsive" src={c4} alt="" />
                    </div>
                    <div className="hom-trend-con">
                      {/* <span><i className="fa fa-futbol-o" aria-hidden="true" /> 2rd augest 2017</span> */}
                      <h2
                        className="txt-effect"
                        style={{ textTransform: "uppercase", fontSize: "16px" }}
                      >
                        CURRENT
                      </h2>

                      {/* <p>The Sports Games also celebrated and showcased sport, thanks to the city’s stunning setting</p> */}
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="hom-top-trends row mrg">
              {/*TOP TRENDINGS*/}
              <div className="col-md-3">
                <a href="/event/:category">
                  <div className="hom-trend">
                    <div className="hom-trend-img">
                      <img className="img-responsive" src={c5} alt="" />
                    </div>
                    <div className="hom-trend-con">
                      {/* <span><i className="fa fa-futbol-o" aria-hidden="true" /> 2rd augest 2017</span> */}
                      <h2
                        className="txt-effect"
                        style={{ textTransform: "uppercase", fontSize: "16px" }}
                      >
                        TECH
                      </h2>

                      {/* <p>The Sports Games also celebrated and showcased sport, thanks to the city’s stunning setting</p> */}
                    </div>
                  </div>
                </a>
              </div>
              {/*TOP TRENDINGS*/}
              <div className="col-md-3">
                <a href="/event/:category">
                  <div className="hom-trend">
                    <div className="hom-trend-img">
                      <img className="img-responsive" src={c6} alt="" />
                    </div>
                    <div className="hom-trend-con">
                      {/* <span><i className="fa fa-futbol-o" aria-hidden="true" /> 2rd augest 2017</span> */}

                      <h2
                        className="txt-effect"
                        style={{ textTransform: "uppercase", fontSize: "16px" }}
                      >
                        ENTERTAINMENT
                      </h2>

                      {/* <p>The Sports Games also celebrated and showcased sport, thanks to the city’s stunning setting</p> */}
                    </div>
                  </div>
                </a>
              </div>
              {/*TOP TRENDINGS*/}
              <div className="col-md-3">
                <a href="/event/:category">
                  <div className="hom-trend">
                    <div className="hom-trend-img">
                      <img className="img-responsive" src={c7} alt="" />
                    </div>
                    <div className="hom-trend-con">
                      {/* <span><i className="fa fa-futbol-o" aria-hidden="true" /> 2rd augest 2017</span> */}

                      <h2
                        className="txt-effect"
                        style={{ textTransform: "uppercase", fontSize: "16px" }}
                      >
                        SCIENCE
                      </h2>

                      {/* <p>The Sports Games also celebrated and showcased sport, thanks to the city’s stunning setting</p> */}
                    </div>
                  </div>
                </a>
              </div>
              {/*TOP TRENDINGS*/}
              <div className="col-md-3">
                <a href="/event/:category">
                  <div className="hom-trend pad-red-bot-0">
                    <div className="hom-trend-img">
                      <img className="img-responsive" src={c8} alt="" />
                    </div>
                    <div className="hom-trend-con">
                      {/* <span><i className="fa fa-futbol-o" aria-hidden="true" /> 2rd augest 2017</span> */}
                      <h2
                        className="txt-effect"
                        style={{ textTransform: "uppercase", fontSize: "16px" }}
                      >
                        CELEBRITIES
                      </h2>

                      {/* <p>The Sports Games also celebrated and showcased sport, thanks to the city’s stunning setting</p> */}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* hero section end */}

        {/* Use Cases for Betdeex */}
        <section className="section bg-6 bg-6-gradient edge bottom-right play-slider">
          <div className="container bring-to-front">
            <div className="inn-title">
              <h1 className="sect-head">How To Play </h1>
            </div>
            <div id="demo" className="carousel slide" data-ride="carousel">
              {/* Indicators */}
              <ul className="carousel-indicators">
                <li data-target="#demo" data-slide-to={0} className="active" />
                <li data-target="#demo" data-slide-to={1} />
                <li data-target="#demo" data-slide-to={2} />
              </ul>
              {/* The slideshow */}
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <section className="section singl-testimonial bg-1 edge top-left">
                    <div className="shape-wrapper">
                      <img
                        className="absolute top right h-100"
                        src={absone}
                        alt="..."
                      />
                    </div>
                    <div className="container-wide bring-to-front">
                      <div className="row gap-y align-items-center">
                        <div className="col-12 col-lg-6 mx-auto">
                          <h2 className="txt-justify">Step 1: Explore</h2>
                          <p className="lead color-2">
                            There will be multiple events to predict from
                            multiple domains like Sports, Politics, Movies,
                            Tech, Science, Entertainment, Celebrities, Current
                            Affairs and more.
                          </p>
                          <p></p>
                        </div>
                        <div className="col-10 col-lg-5 pr-0">
                          <div className="device browser" data-aos="fade-left">
                            <div className="screen">
                              <img
                                className="img-responsive shadow"
                                src={betdex1}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="carousel-item">
                  <section className="section singl-testimonial bg-1 edge top-left">
                    <div className="shape-wrapper">
                      <img
                        src={absone}
                        className="absolute top right h-100"
                        alt="..."
                      />
                    </div>
                    <div className="container-wide bring-to-front">
                      <div className="row gap-y align-items-center">
                        <div className="col-12 col-lg-6 mx-auto">
                          <h2 className="txt-justify">
                            Step 2: Choose an Event
                          </h2>
                          <p className="lead color-2">
                            An 'event' is a prediction event which you can
                            choose from the list of multiple events on BetDeEx
                            platform. You can select your favorite event(s) and
                            start prediction based on your skills.
                          </p>
                        </div>
                        <div className="col-10 col-lg-5 pr-0">
                          <div className="device browser" data-aos="fade-left">
                            <div className="screen">
                              <img
                                className="img-responsive shadow"
                                src={betdeex2}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="carousel-item">
                  <section className="section singl-testimonial bg-1 edge top-left">
                    <div className="shape-wrapper">
                      <img
                        src={absone}
                        className="absolute top right h-100"
                        alt="..."
                      />
                    </div>
                    <div className="container-wide bring-to-front">
                      <div className="row gap-y align-items-center">
                        <div className="col-12 col-lg-6 mx-auto">
                          <h2 className="txt-justify">Step 3: Predict!</h2>
                          <p className="lead color-2">
                            Predict using ES on the outcome of the event and
                            wait for results. If you are lucky to win, you will
                            get rewarded through the BetDeEx Smart Contract.
                          </p>
                        </div>
                        <div className="col-10 col-lg-5 pr-0">
                          <div className="device browser" data-aos="fade-left">
                            <div className="screen">
                              <img
                                src={betdeex3}
                                alt=""
                                className="img-responsive shadow"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              {/* Left and right controls */}
              <a
                className="carousel-control-prev"
                style={{ color: "#000" }}
                href="#demo"
                data-slide="prev"
              >
                <i className="fa fa-arrow-left"></i>
              </a>
              <a
                className="carousel-control-next"
                href="#demo"
                data-slide="next"
              >
                <i className="fa fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </section>
        {/*Currencies of Betdeex */}

        {/* ./CTA - Create Account */}
        <section
          className="section bg-6 b-b edge"
          style={{ marginTop: "-2px" }}
        >
          <div className="container">
            <div className="inn-title">
              <h1 className="sect-head">FAQ's </h1>
            </div>
            <div className="section-heading text-center">
              {/* <p className="color-2 lead">Era Swap Ecosystem includes interlinked multiple platforms where Era Swap Tokens can
                  be utilized for exchange of services, availing discounts, getting rewards and other utilities. Era Swap Team will identify and help build more such platforms
                  in future which are built for the community and willing to incorporate ES reward system.</p> */}
            </div>
            <div className="">
              <section className="section bg-6">
                <div className="container" style={{ marginTop: "-64px" }}>
                  <div className="row gap-y">
                    <div className="col-md-12">
                      <div
                        className="accordion accordion-clean"
                        id="faqs-accordion"
                      >
                        <div className="card mb-3 bx-effect">
                          <div className="card-header">
                            <a
                              href="/"
                              className="card-title btn"
                              data-toggle="collapse"
                              data-target="#v1-q1"
                            >
                              What is Era Swap Token?
                            </a>
                          </div>
                          <div
                            id="v1-q1"
                            className="collapse"
                            data-parent="#faqs-accordion"
                          >
                            <div className="card-body">
                              Era Swap Token or ES is a decentralized utility
                              token currently based on Ethereum blockchain
                              (ERC20) which will be used on multiple platforms
                              for exchange of services(P2P), avail discounts,
                              get rewards and many more utility.
                            </div>
                          </div>
                        </div>

                        <div className="card mb-3 bx-effect">
                          <div className="card-header">
                            <a
                              href="/"
                              className="card-title btn"
                              data-toggle="collapse"
                              data-target="#v1-q2"
                            >
                              What is BetDeEx?
                            </a>
                          </div>
                          <div
                            id="v1-q2"
                            className="collapse"
                            data-parent="#faqs-accordion"
                          >
                            <div className="card-body">
                              BetDeEx is a Decentralized App (DApp) which is
                              based on pre-defined set of rules enforced through
                              Smart Contracts to govern the prediction activity
                              and distribution of winnings.
                            </div>
                            <div className="offset-md-4 col-md-4">
                              <div
                                style={{
                                  position: "relative",
                                  height: 0,
                                  overflow: "hidden",
                                  maxWidth: "100%",
                                  paddingBottom: "56.25%",
                                  marginBottom: "20px",
                                }}
                              >
                                <iframe
                                  src="https://www.youtube.com/embed/reKG7T8ciGs"
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  frameBorder={0}
                                  allowFullScreen
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card mb-3 bx-effect">
                          <div className="card-header">
                            <a
                              href="/"
                              className="card-title btn"
                              data-toggle="collapse"
                              data-target="#v1-q3"
                            >
                              How to Predict On Events on BetDeEx?
                            </a>
                          </div>
                          <div
                            id="v1-q3"
                            className="collapse"
                            data-parent="#faqs-accordion"
                          >
                            <div className="card-body">
                              Please watch this video to understand "Walkthrough
                              On BetDeEx & How To Predict On Events"
                            </div>
                            <div className="offset-md-4 col-md-4">
                              <div
                                style={{
                                  position: "relative",
                                  height: 0,
                                  overflow: "hidden",
                                  maxWidth: "100%",
                                  paddingBottom: "56.25%",
                                  marginBottom: "20px",
                                }}
                              >
                                <iframe
                                  src="https://www.youtube.com/embed/j2a8Una4lEw"
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  frameBorder={0}
                                  allowFullScreen
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card mb-3 bx-effect">
                          <div className="card-header">
                            <a
                              href="/"
                              className="card-title btn"
                              data-toggle="collapse"
                              data-target="#v1-q4"
                            >
                              Do you store my private keys, Keystore or mnemonic
                              in BetDeEx?
                            </a>
                          </div>
                          <div
                            id="v1-q4"
                            className="collapse"
                            data-parent="#faqs-accordion"
                          >
                            <div className="card-body">
                              When you load Wallet in the BetDeEx ÐApp, your
                              private keys stay only on your computer. The
                              BetDeEx ÐApp directly talks with blockchain hence
                              any centralized intermediate server is not at all
                              required and hence any of your data is NOT sent to
                              our servers (we are also not recording any usage
                              data). Your computer signs any transactions you
                              authorise and only the signed transaction is
                              submitted to blockchain for being included in
                              blocks by miners who are mining in Ethereum. When
                              you logout or even refresh page your private keys
                              are erased from computer’s memory. The BetDeEx
                              ÐApp source code is open source and anyone can
                              check what is happening.
                            </div>
                          </div>
                        </div>
                        <div className="card mb-3 bx-effect">
                          <div className="card-header">
                            <a
                              href="/"
                              className="card-title btn"
                              data-toggle="collapse"
                              data-target="#v1-q5"
                            >
                              What is Gas Fee / Network Fee in BetDeEx?
                            </a>
                          </div>
                          <div
                            id="v1-q5"
                            className="collapse"
                            data-parent="#faqs-accordion"
                          >
                            <div className="card-body">
                              Ethereum is a decentralized and immutable
                              platform. Tens of thousands of miners dedicate
                              their computers to maintain the Ethereum network.
                              For compensation, the concept of the gas fee is
                              there. All transactions on Ethereum Network cost
                              gas and this fee is transferred to miners in ETH.
                              Users can choose to pay less or more gas to
                              miners. A miner can choose which transactions to
                              mine, and generally, they give priority to those
                              transactions which give them more fees. You can
                              customize your gas fees in the advanced settings
                              while making staking, withdrawal or any
                              transaction.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

        {/* <div>
				<div className="sect-container">
					<div className="wrapper-container">
						<h1 className="sect-head">POPULAR EVENTS</h1>
						<div className="row inner-sect">
							<div>
								<div className="pop-ev-box">

								</div>
								<p className="bd-lines">Market/Category</p>
								<p>Will Ethereum Price Cross</p>
							</div>

							<div>
								<div className="pop-ev-box">

								</div>
								<p className="bd-lines">Market/Category</p>
								<p>Will Ethereum Price Cross</p>
							</div>

							<div>
								<div className="pop-ev-box">

								</div>
								<p className="bd-lines">Market/Category</p>
								<p>Will Ethereum Price Cross</p>
							</div>

								<div>
								<div className="pop-ev-box">

								</div>
								<p className="bd-lines">Market/Category</p>
								<p>Will Ethereum Price Cross</p>
							</div>
							
						</div>
					</div>
				</div>
			</div> */}
      </section>
    );
  }
}
