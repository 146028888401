import React, { Component } from "react";
import "./CreatePredictionEvents.css";
import { ethers } from "ethers";
import { AllCategory } from "../../utils/AllCategories";
import Swal from "sweetalert2";
import ipfs from "./ipfs";

type Props = {};

type State = {
  betName: string;
  category: number;
  subCategory: number;
  description: string;
  minBet: string | null;
  pricePerThousand: number | null;
  incentive: number | null;
  isDraw: boolean | null;
  pauseTimestamp: string | null;
  spinner: boolean;
};

export class CreatePredictionEvents extends Component<Props, State> {
  state: State = {
    betName: "",
    category: -1,
    subCategory: -1,
    description: "",
    minBet: "50",
    pricePerThousand: 998,
    incentive: 0,
    isDraw: false,
    pauseTimestamp: null,
    spinner: false,
  };

  categoryInstance: AllCategory;

  constructor(props: Props) {
    super(props);
    this.categoryInstance = new AllCategory();
  }

  componentDidMount() {}

  loadIPFS = async () => {
    let asyncGenerator = await ipfs.add(JSON.stringify(this.state.description));
    console.log(asyncGenerator);
    return asyncGenerator.path;
  };

  renderTimeStamp(datetime: string): number {
    const date = new Date(datetime);
    const timeStamp: number = date.getTime();
    return timeStamp / 1000;
  }
  createBet = async (e) => {
    e.preventDefault();
    this.setState({ spinner: true });
    const ipfsHash = await this.loadIPFS();
    if (window.wallet) {
      const A = await window.BetDeExInst.connect(
        window.wallet
      ).populateTransaction.createBet(
        this.state.betName,
        this.state.category,
        this.state.subCategory,
        ethers.utils.parseEther(this.state.minBet),
        this.state.pricePerThousand,
        this.state.incentive,
        this.state.isDraw,
        this.renderTimeStamp(this.state.pauseTimestamp),
        ipfsHash
      );
      console.log("call : ", A);
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this action!",
        html: `<p>You will not be able to undo this action!</p>
                      <h1 style={{fontStyle:"bold"}}> Value : ${
                        A.value ? ethers.utils.formatEther(A?.value) : "0"
                      } </h1>
                      <small> To : ${A.to} </small><br/><small> From : ${
          A.from
        } ES </small>
                      <p> gasFee : ${A?.gasPrice || "0"} </p>`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, cancel!",
        confirmButtonText: "Yes, do it!",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return window.BetDeExInst.connect(window.wallet)
            .createBet(
              this.state.betName,
              this.state.category,
              this.state.subCategory,
              ethers.utils.parseEther(this.state.minBet),
              this.state.pricePerThousand,
              this.state.incentive,
              this.state.isDraw,
              this.renderTimeStamp(this.state.pauseTimestamp),
              ipfsHash
            )
            .then(async (res) => {
              const x = await res.wait();
              const event = await window.BetDeExInst.interface.parseLog(
                x.logs[0]
              );

              Swal.fire({
                title: "Good job!",
                icon: "success",
                html: `<p>You Proposal has been Added</p><br/>Transaction Hash<a>${res.hash}</a><br/>
                                    <a href="/event-detail/${event.args[1]}" >  go here  : ${event.args[1]}</a>`,
              });
            })
            .catch((e) => {
              Swal.fire("Oops...!", `${e}`, "error");
            });
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Please Connect to wallet ...",
      });
    }

    this.setState({ spinner: false });
  };

  render() {
    return (
      <section>
        <div className="row innerpage-title">
          <div className="col-md-12">
            <h1 className="event-head">Create Prediction</h1>
          </div>
        </div>
        <div
          className="sect-container"
          style={{ maxWidth: "700px", margin: "auto" }}
        >
          <div className=" gap-y pdt30 pdb20 mb20">
            <div className="col-md-12">
              <div className="col-12  mx-auto mt40 mb40">
                <form onSubmit={this.createBet}>
                  <div className="form-group">
                    <label>Prediction Name:</label>
                    <input
                      className="form-control"
                      placeholder="Enter Bet name"
                      onChange={(event) =>
                        this.setState({ betName: event.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Minimum Bet amount:</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter minimum Bet amount"
                      onChange={(event) =>
                        this.setState({ minBet: event.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label> Select Category:</label>
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(event) =>
                        this.setState({ category: Number(event.target.value) })
                      }
                      required
                    >
                      <option></option>
                      {this.categoryInstance
                        .getCategory()
                        ?.map((category: string, index: number) => {
                          return (
                            <option value={index} key={index}>
                              {category}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label> Select Sub-Category:</label>
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(event) =>
                        this.setState({
                          subCategory: Number(event.target.value),
                        })
                      }
                      required
                    >
                      <option></option>
                      {this.categoryInstance
                        .getSubCategory(this.state.category)
                        ?.map((category: string, index: number) => {
                          return (
                            <option value={index} key={index}>
                              {category}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Pause Timestamp:</label>
                    <div>
                      <input
                        className="form-control"
                        type="datetime-local"
                        name="pausetime"
                        value={this.state.pauseTimestamp}
                        onChange={(event) =>
                          this.setState({ pauseTimestamp: event.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Source of Prediction:</label>
                    <textarea
                      rows={5}
                      className="form-control"
                      id="description"
                      placeholder="description"
                      onChange={(event) =>
                        this.setState({ description: event.target.value })
                      }
                      required
                    />
                    <div className="form-text text-muted">
                      For Style use{" "}
                      <a
                        className="text-primary"
                        href="https://guides.github.com/pdfs/markdown-cheatsheet-online.pdf"
                      >
                        MarkDown
                      </a>{" "}
                      or embed code
                    </div>
                    <p className="custom-note">
                      * Please mention the source of prediction and also attach
                      link
                    </p>
                  </div>
                  <div className="form-group">
                    <label> Draw Possible:</label>
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(event) =>
                        this.setState({ isDraw: Boolean(event.target.value) })
                      }
                      required
                    >
                      <option></option>
                      <option value="true">YES</option>
                      <option value="false">NO</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Enter Distribution % of Total prize:</label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Enter price %"
                      onChange={(event) =>
                        this.setState({
                          pricePerThousand: Number(
                            parseFloat(event.target.value) * 10
                          ),
                        })
                      }
                    />
                    <div className="form-text text-muted">
                      *this % of amount will distribute among all winners
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Incentive % of Creator's Fee :</label>
                    <input
                      type="number"
                      className="form-control"
                      min="0"
                      max="99"
                      placeholder="Enter Incentive"
                      onChange={(event) =>
                        this.setState({ incentive: Number(event.target.value) })
                      }
                    />
                    <div className="form-text text-muted">
                      this incentive is destributed from creator's fee/share
                    </div>
                  </div>
                  <input
                    type="submit"
                    className="btn btn-success"
                    disabled={this.state.spinner}
                    value={
                      this.state.spinner ? "Loading..." : "Create Prediction"
                    }
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
