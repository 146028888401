import { ethers } from "ethers";
import { BetFactory } from "../../ehtereum/Typechain/BetFactory";
interface Bet {
  category: number;
  subcategory: number;
  name: string;
  description: string;
  minimumBet: number;
  prize: number;
  startTime: Date;
  pauseTime: Date;
  TotalPrize: number;
  Incentive: number;
  Amount: number[];
}

interface BetDetails {
  isResult: boolean;
  endTime: Date;
  endBy: string;
  RewardX: number;
  result: number;
}

interface Pred {
  address: string;
  amount: string;
}

export async function fetchData(address: string): Promise<Bet> {
  const bet = BetFactory.connect(address, window.providerESN);
  const category: number = await bet.category();
  const subcategory: number = await bet.subCategory();
  const name: string = await bet.description();
  const description: string = await bet.other();
  const minimumBet: number = Number(
    ethers.utils.formatEther(await bet.minimumBetInExaEs())
  );
  const prize: number = Number(await bet.prizePercentPerThousand()) / 10;
  const Incentive: number = Number(await bet.Incentive());
  const startTime: Date = new Date(
    (await bet.creationTimestamp()).toNumber() * 1000
  );
  const pauseTime: Date = new Date(
    (await bet.pauseTimestamp()).toNumber() * 1000
  );
  const TotalPrize: number = Number(
    ethers.utils.formatEther(await bet.totalContractBalance())
  );
  const Amount: number[] = [0, 0, 0];
  Amount[0] = Number(
    ethers.utils.formatEther(await bet.totalBetTokensInExaEsByChoice(0))
  );
  Amount[1] = Number(
    ethers.utils.formatEther(await bet.totalBetTokensInExaEsByChoice(1))
  );
  Amount[2] = Number(
    ethers.utils.formatEther(await bet.totalBetTokensInExaEsByChoice(2))
  );
  return {
    category,
    subcategory,
    name,
    description,
    minimumBet,
    prize,
    startTime,
    pauseTime,
    TotalPrize,
    Incentive,
    Amount,
  };
}

export async function fetchResult(address: string): Promise<BetDetails> {
  const bet = BetFactory.connect(address, window.providerESN);
  const endUnix = (await bet.endTimestamp()).toNumber() * 1000;
  const isResult: boolean = endUnix ? true : false;
  const endTime: Date = new Date(endUnix);
  const endBy: string = await bet.endedBy();
  const result: number = await bet.finalResult();
  const RewardX: number =
    Number(ethers.utils.formatEther(await bet.totalPrize())) /
      Number(
        ethers.utils.formatEther(
          await bet.totalBetTokensInExaEsByChoice(result)
        )
      ) || 0;
  return { isResult, endTime, endBy, RewardX, result };
}

export async function fetchYes(
  address: string,
  index: number
): Promise<Pred[]> {
  const filter = window.BetDeExInst.filters.NewBetting(
    address,
    null,
    index,
    null
  );
  const logs = await window.BetDeExInst.queryFilter(filter);
  const parseLogs = logs.map((log) =>
    window.BetDeExInst.interface.parseLog(log)
  );
  const AllPrediction: Pred[] = parseLogs.map((ele) => ({
    address: ele.args[1],
    amount: ethers.utils.formatEther(ele.args[3]),
  }));
  return AllPrediction;
}
