/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "./Footer.css";
import { logo } from "../Images/Image";

export const Footer = () => {
  return (
    <header>
      <section className="footers pb-3 footer-bgd-color">
        <div className="container pt-5">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 footers-one">
              <div className="footers-logo">
                <img className="btdex-logo" alt="logo" src={logo} />
              </div>
              <div className="footers-info mt-3">
                <p>
                  Please read the Disclaimer, Terms and Conditions, Legal Terms
                  carefully before strating with BetDeEx
                </p>
              </div>
              <div className="footer-social-flex mb30">
                <ul className="social-menu">
                  <li>
                    <a href="https://www.facebook.com/eraswap" target="_blank">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/eraswap/"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/eraswap/?hl=en"
                      target="_blank"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>{" "}
                  </li>
                  <li>
                    <a href="https://twitter.com/EraSwapTech" target="_blank">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://medium.com/@eraswap" target="_blank">
                      <i className="fa fa-medium"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://eraswap.tumblr.com/" target="_blank">
                      <i className="fa fa-tumblr"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/eraswap" target="_blank">
                      <i className="fa fa-telegram"></i>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="https://github.com/KMPARDS" target="_blank">
                      <i className="fa fa-github"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.reddit.com/user/EraSwap"
                      target="_blank"
                    >
                      <i className="fa fa-reddit"></i>
                    </a>{" "}
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCGCP4f5DF1W6sbCjS6y3T1g?view_as=subscriber"
                      target="_blank"
                    >
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 footers-two">
              <h5>Other links </h5>
              <ul className="list-unstyled">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/results-previous-events">Results</a>
                </li>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/faq">Faq's</a>
                </li>
                <li>
                  <a href="/event">All Events</a>
                </li>

                <li>
                  <a target="_blank" href="pdf/betdeex-user-guide.pdf">
                    User Manual
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/">
                    BetDeEx DApp Smart Contract
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 footers-three">
              <h5>Quik Links </h5>
              <ul className="list-unstyled">
                <li>
                  <a
                    target="_blank"
                    href="http://eraswaptoken.io/pdf/eraswap_whitepaper.pdf"
                  >
                    ES White Paper
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="http://eraswaptoken.io/pdf/era-swap-howey-test-letter-august7-2018.pdf"
                  >
                    Howey Test
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="http://eraswaptoken.io/pdf/es-statuary-warning.pdf"
                  >
                    Statuary Warning
                  </a>
                </li>
                <li>
                  <a target="_blank" href="pdf/Terms-Conditions-BetdeEx.pdf">
                    Terms of Use
                  </a>
                </li>
                <li>
                  <a target="_blank" href="pdf/privacy-policy-BerdeEx.pdf">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="http://eraswaptoken.io/pdf/es-statuary-warning.pdf"
                  >
                    Era Swap Terms & Conditins
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="copyright border">
        <div className="">
          <div className="row text-center">
            <div className="col-md-12 pt-3">
              <p className="copyright">© Eraswap 2020 | All rights reserved</p>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
};
