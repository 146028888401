/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./Faq.css";

export class Faq extends React.Component {
  render() {
    return (
      <section>
        <div className="container">
          <div className="row innerpage-title">
            <div className="col-md-6">
              <h1 className="event-head">FAQ</h1>
            </div>
            {/* <div className="col-md-6 searchBox mb20 text-right">
								<form>
									<input type="search" placeholder="Search Categories" />
								</form>
							</div> */}
          </div>
          <div className="sect-container">
            <div className=" gap-y pdt30 pdb20">
              <div className="col-md-12">
                <div className="accordion accordion-clean" id="faqs-accordion">
                  <div className="card mb-3 bx-effect">
                    <div className="card-header">
                      <a
                        href="/"
                        className="card-title btn"
                        data-toggle="collapse"
                        data-target="#v1-q1"
                      >
                        What is Era Swap Token?
                      </a>
                    </div>
                    <div
                      id="v1-q1"
                      className="collapse"
                      data-parent="#faqs-accordion"
                    >
                      <div className="card-body">
                        Era Swap Token or ES is a decentralized utility token
                        currently based on Ethereum blockchain (ERC20) which
                        will be used on multiple platforms for exchange of
                        services(P2P), avail discounts, get rewards and many
                        more utility.
                      </div>
                    </div>
                  </div>

                  <div className="card mb-3 bx-effect">
                    <div className="card-header">
                      <a
                        href="/"
                        className="card-title btn"
                        data-toggle="collapse"
                        data-target="#v1-q2"
                      >
                        What is BetDeEx?
                      </a>
                    </div>
                    <div
                      id="v1-q2"
                      className="collapse"
                      data-parent="#faqs-accordion"
                    >
                      <div className="card-body">
                        BetDeEx is a Decentralized App (DApp) which is based on
                        pre-defined set of rules enforced through Smart
                        Contracts to govern the prediction activity and
                        distribution of winnings.
                      </div>
                      <div className="offset-md-4 col-md-4">
                        <div
                          style={{
                            position: "relative",
                            height: 0,
                            overflow: "hidden",
                            maxWidth: "100%",
                            paddingBottom: "56.25%",
                            marginBottom: "20px",
                          }}
                        >
                          <iframe
                            src="https://www.youtube.com/embed/reKG7T8ciGs"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                            frameBorder={0}
                            allowFullScreen
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-3 bx-effect">
                    <div className="card-header">
                      <a
                        href="/"
                        className="card-title btn"
                        data-toggle="collapse"
                        data-target="#v1-q3"
                      >
                        How to Predict On Events on BetDeEx?
                      </a>
                    </div>
                    <div
                      id="v1-q3"
                      className="collapse"
                      data-parent="#faqs-accordion"
                    >
                      <div className="card-body">
                        Please watch this video to understand "Walkthrough On
                        BetDeEx & How To Predict On Events"
                      </div>
                      <div className="offset-md-4 col-md-4">
                        <div
                          style={{
                            position: "relative",
                            height: 0,
                            overflow: "hidden",
                            maxWidth: "100%",
                            paddingBottom: "56.25%",
                            marginBottom: "20px",
                          }}
                        >
                          <iframe
                            src="https://www.youtube.com/embed/j2a8Una4lEw"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                            frameBorder={0}
                            allowFullScreen
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-3 bx-effect">
                    <div className="card-header">
                      <a
                        href="/"
                        className="card-title btn"
                        data-toggle="collapse"
                        data-target="#v1-q4"
                      >
                        Do you store my private keys, Keystore or mnemonic in
                        BetDeEx?
                      </a>
                    </div>
                    <div
                      id="v1-q4"
                      className="collapse"
                      data-parent="#faqs-accordion"
                    >
                      <div className="card-body">
                        When you load Wallet in the BetDeEx ÐApp, your private
                        keys stay only on your computer. The BetDeEx ÐApp
                        directly talks with blockchain hence any centralized
                        intermediate server is not at all required and hence any
                        of your data is NOT sent to our servers (we are also not
                        recording any usage data). Your computer signs any
                        transactions you authorise and only the signed
                        transaction is submitted to blockchain for being
                        included in blocks by miners who are mining in Ethereum.
                        When you logout or even refresh page your private keys
                        are erased from computer’s memory. The BetDeEx ÐApp
                        source code is open source and anyone can check what is
                        happening.
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3 bx-effect">
                    <div className="card-header">
                      <a
                        href="/"
                        className="card-title btn"
                        data-toggle="collapse"
                        data-target="#v1-q5"
                      >
                        What is Gas Fee / Network Fee in BetDeEx?
                      </a>
                    </div>
                    <div
                      id="v1-q5"
                      className="collapse"
                      data-parent="#faqs-accordion"
                    >
                      <div className="card-body">
                        Ethereum is a decentralized and immutable platform. Tens
                        of thousands of miners dedicate their computers to
                        maintain the Ethereum network. For compensation, the
                        concept of the gas fee is there. All transactions on
                        Ethereum Network cost gas and this fee is transferred to
                        miners in ETH. Users can choose to pay less or more gas
                        to miners. A miner can choose which transactions to
                        mine, and generally, they give priority to those
                        transactions which give them more fees. You can
                        customize your gas fees in the advanced settings while
                        making staking, withdrawal or any transaction.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
