// import { ethers } from 'ethers';
import { BetDeExFactory } from "./Typechain/BetDeExFactory";
import { CustomProvider } from "eraswap-sdk";

// const nodeUrl = process.env.REACT_APP_NODE_URL || 'https://node2.testnet.eraswap.network/';

window.providerESN = new CustomProvider("mainnet");
window.provider = new CustomProvider("mainnet");

window.BetDeExInst = BetDeExFactory.connect(
  "0x7A2B0621546e18A62633427a00c5C6c58dA797e4",
  window.providerESN
);

// export const BetInst = BetFactory.connect(addresses.Bet, providerESN);

// export const providerESN = new ethers.providers.JsonRpcProvider(nodeUrl);

console.log("custom provider", window.providerESN);
