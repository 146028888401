import React, { Component } from "react";
import "../Events/Events.css";
import WalletContext from "../../utils/WalletContext";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
import Loader from "../Loader";

type Props = {};

type State = {
  choice: number;
  spinner: boolean;
  data: any[];
};

export class ResultsPreviousEvents extends Component<Props, State> {
  static contextType = WalletContext;
  state: State = {
    choice: 0,
    spinner: false,
    data: [],
  };

  async componentDidMount() {
    this.setState({ spinner: true });
    const filter = window.BetDeExInst.filters.EndBetContract(
      null,
      null,
      null,
      null,
      null,
      null
    );
    const logs = await window.BetDeExInst.queryFilter(filter);
    const parseLogs = logs.map((log) =>
      window.BetDeExInst.interface.parseLog(log)
    );
    const resultAll = parseLogs.map((ele) => ele.args);
    console.log(resultAll);
    resultAll.reverse();
    this.setState({ spinner: false, data: resultAll });
  }

  resultArray = [
    <span className="badge badge-success">Yes</span>,
    <span className="badge badge-danger">No</span>,
    <span className="badge badge-warning">Draw</span>,
  ];

  render() {
    return (
      <section>
        <div className="container">
          <div className="row innerpage-title">
            <div className="col-md-12">
              <h1 className="event-head">Results Previous Events</h1>
            </div>
          </div>
          {this.state.spinner ? (
            <Loader />
          ) : (
            <div className="sect-container">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>EVENT</th>
                      <th>WINNING CHOICE </th>
                      <th>PRIZE POOL</th>
                      <th>PRIZE POOL</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((ele) => (
                      <tr>
                        <td>
                          <p className="font-weight-bold">{ele[4]}</p>
                          {/* <small>Smart Contract Address: {ele[1]}</small><br/>*/}
                          <small>Declared By: {ele[0]}</small>
                        </td>
                        <td>{this.resultArray[ele[2]]}</td>
                        <td>{Number(ethers.utils.formatEther(ele[3]))}</td>
                        <td>{new Date(ele[5].toNumber() * 1000).toString()}</td>
                        <td>
                          <Link
                            to={"/event-detail/" + ele[1]}
                            className="btn btn-primary text-light"
                          >
                            View Details{" "}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="pagination text-center ml10 mb10 mt10">
                <nav aria-label="...">
                  <ul className="pagination">
                    <li className="page-item disabled">
                      <span className="page-link">Previous</span>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/">
                        1
                      </a>
                    </li>
                    <li className="page-item active">
                      <span className="page-link">
                        2<span className="sr-only">(current)</span>
                      </span>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}
