import React, { Component } from "react";
import "./Sidebar.css";
import { AllCategory } from "../../utils/AllCategories";
import { Link } from "react-router-dom";

type Props = {};

type State = {};

export class Sidebar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.categoryInstance = new AllCategory();
  }

  categoryInstance: AllCategory;

  render() {
    return (
      <div>
        <div className="sect-container">
          <div className="sidebar-container">
            <div className="catg-side">CATEGORIES</div>
            {/* <div className="catg-side">POPULAR CATEGORIES</div> */}
            <ul className="sidbarmenu">
              <li className="main-ev-desc ev-border">
                <Link to={"/event"}>
                  <p className="sd-txt">All</p>
                </Link>
              </li>
              {this.categoryInstance
                .getCategory()
                ?.map((category: string, index: number) => {
                  return (
                    <li className="main-ev-desc ev-border">
                      <Link to={"/event/" + category}>
                        <p className="sd-txt">{category}</p>
                      </Link>
                      <div className="bet-value">10</div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
