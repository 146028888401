import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { BetFactory } from "../../ehtereum/Typechain/BetFactory";
import "./EventDetail.css";

function renderSecondsRemaining(numberOfSeconds: number): string {
  const days = Math.floor(numberOfSeconds / 60 / 60 / 24);
  const hours = Math.floor((numberOfSeconds - days * 60 * 60 * 24) / 60 / 60);
  const minutes = Math.floor(
    (numberOfSeconds - days * 60 * 60 * 24 - hours * 60 * 60) / 60
  );
  // const seconds = numberOfSeconds - days * 60 * 60 * 24 - hours * 60 * 60 - minutes * 60;

  return `${days} days, ${hours} hours and  ${minutes} minutes `;
}

function renderTimestampRemaining(unixTimestampSeconds: number): string {
  const currentTimestamp = Math.round(Date.now() / 1000);
  let secondsRemaining = currentTimestamp - unixTimestampSeconds;
  if (secondsRemaining < 0) secondsRemaining = 0;
  return renderSecondsRemaining(secondsRemaining);
}

export default function CommentBox(props: { address: string }) {
  const betInstance = BetFactory.connect(props.address, window.providerESN);
  const [comments, setComments] = useState<any>([]);
  const [comment, setComment] = useState<string>("");
  const showComments = async () => {
    const filter = betInstance.filters.Comments(null, null, null);
    const logs = await betInstance.queryFilter(filter);
    const parsedLogs = logs.map((log) => betInstance.interface.parseLog(log));
    const updatevalues = parsedLogs.map((parsedLogs) => parsedLogs.args);
    console.log("All Comments: ", updatevalues);
    setComments(updatevalues);
  };
  const postComment = async () => {
    // e.preventDefault();
    if (window.wallet) {
      const A = await betInstance
        .connect(window.wallet)
        .populateTransaction.addComments(comment);
      console.log("call : ", A);
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this action!",
        html: `<p>You will not be able to undo this action!</p>
                <h1 style={{fontStyle:"bold"}}> Value : ${
                  A.value ? ethers.utils.formatEther(A?.value) : "0"
                } </h1>
                <small> To : ${A.to} </small><br/><small> From : ${
          A.from
        } ES </small>
                <p> gasFee : ${A?.gasPrice || "0"} </p>`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, cancel!",
        confirmButtonText: "Yes, do it!",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return betInstance
            .connect(window.wallet)
            .addComments(comment)
            .then(async () => {
              Swal.fire({
                title: "Good job!",
                icon: "success",
                text: "You have post your comment ",
              });
            })
            .catch(async () => {
              try {
                const A = await betInstance
                  .connect(window.wallet)
                  .estimateGas.addComments(comment);
                console.log(A);
              } catch (e) {
                console.log("Error is : ", e);
                Swal.fire("Oops...!", `${e}`, "error");
              }
            });
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Please Connect to wallet ...",
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await showComments();
      } catch {
        console.log("error");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <h3>Comments</h3> <hr />
        </div>
      </div>
      <div className="comments">
        {/* Start here  */}
        {comments.map((ele) => {
          return (
            <div className="row">
              <div className="col-1">
                <div className="thumbnail">
                  <img
                    alt="img"
                    className="img-responsive user-photo"
                    src="https://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                  />
                </div>
              </div>
              <div className="col-11">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <strong className="text-bold ">{ele[0]}</strong>{" "}
                    <span className="text-muted">
                      {" "}
                      {renderTimestampRemaining(ele[2].toNumber())} ago
                    </span>
                  </div>
                  <div className="panel-body">{ele[1]}</div>
                </div>
              </div>
            </div>
          );
        })}{" "}
        {/* End here */}
      </div>

      <textarea
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        className="form-control m-2 col-sm-8 p-3"
        placeholder="Write Something here ..."
      ></textarea>
      <button onClick={postComment} className="btn btn-primary">
        Post
      </button>
    </div>
  );
}
