import React, { Component } from "react";
import "./Events.css";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { Link, RouteComponentProps } from "react-router-dom";
import { AllCategory } from "../../utils/AllCategories";
import Loader from "../Loader";

interface NewPredictEvent {
  category: number;
  deployer: string;
  description: string;
  subCategory: number;
  contractAddress: string;
  Approved: boolean;
}

type Props = { category: string };

type State = {
  newPredictEvent: NewPredictEvent[];
  spinner: boolean;
};

interface MatchParams {
  category: string;
}

export class Event extends Component<RouteComponentProps<MatchParams>, State> {
  constructor(props: RouteComponentProps<MatchParams>) {
    super(props);
    this.state = {
      newPredictEvent: [],
      spinner: false,
    };
  }

  async componentDidMount() {
    this.setState({ spinner: true });

    await this.fetchNewBetEvent().catch((e) => console.log(e));
    this.setState({ spinner: false });
  }
  async componentWillReceiveProps() {
    this.forceUpdate();
    this.setState({ spinner: true, newPredictEvent: [] });
    await this.fetchNewBetEvent().catch((e) => console.log(e));
    await this.fetchNewBetEvent().catch((e) => console.log(e));
    this.setState({ spinner: false });
  }

  allCategories = new AllCategory();

  async fetchNewBetEvent() {
    const index: number = this.allCategories.getIndex(
      this.props.match.params.category
    );
    console.log("index : ", index);

    const data = await window.BetDeExInst.queryFilter(
      window.BetDeExInst.filters.NewBetEvent(null, null, index, null, null)
    );
    console.log("fetchbet", data);
    const betList = data.map((log) => {
      return window.BetDeExInst.interface.parseLog(log);
    });
    console.log("check a", betList);
    const newBetData = await Promise.all(
      betList.map(async (log) => ({
        category: log.args["_category"],
        deployer: log.args["_deployer"],
        description: log.args["_description"],
        subCategory: log.args["_subCategory"],
        contractAddress: log.args["_contractAddress"],
        Approved: await window.BetDeExInst.isBetValid(
          log.args["_contractAddress"]
        ),
      }))
    );
    console.log("newBetData", newBetData);
    newBetData.reverse();
    this.setState({
      newPredictEvent: newBetData,
    });
  }

  render() {
    console.log("newprediction", this.state.newPredictEvent);
    console.log("category match is::", this.props.match.params.category);
    return (
      <section>
        <div className="d-flex" id="wrapper"></div>
        <div className="row">
          <div className="col-md-2">
            <Sidebar />
          </div>
          {this.state.spinner ? (
            <Loader />
          ) : (
            <div className="wrapper-container">
              <div className="row innerpage-title">
                <div className="col-md-6">
                  <h1 className="event-head">
                    {this.props.match.params.category}
                  </h1>
                </div>
                <div className="col-md-6 searchBox mb20 text-right">
                  <form>
                    <input type="search" placeholder="Search Categories" />
                  </form>
                </div>
              </div>

              <div className="sect-container">
                {this.state.newPredictEvent?.length ? (
                  this.state.newPredictEvent.map((event, i) => (
                    <div className="row pd-box">
                      <div className="wd-event">
                        <div className="main-ev-desc">
                          <div className="catg-ev-one">
                            <p>
                              CATEGORY /{" "}
                              <span className="bd-lines">
                                {
                                  this.allCategories.categoryArray[
                                    event.category
                                  ]
                                }
                              </span>
                            </p>
                            <p>
                              {" "}
                              /
                              <span className="bd-lines">
                                {
                                  this.allCategories.subCategoryArray[
                                    event.category
                                  ][event.subCategory]
                                }
                              </span>
                            </p>
                          </div>
                        </div>
                        {event.Approved ? (
                          <div className="badge bg-success text-light">
                            <i
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>{" "}
                            Approved{" "}
                          </div>
                        ) : (
                          <div className="badge bg-danger text-light">
                            <i className="fas fa-cross"></i> Not Approved Yet
                          </div>
                        )}

                        <h3 className="ev-title">{event.description}</h3>
                        <div className="view-rght mt40 mb10">
                          <Link to="" className="deployer-style">
                            {event.deployer.slice(0, 20)}......
                          </Link>
                          <Link
                            to={"/event-detail/" + event.contractAddress}
                            className="bet-view"
                          >
                            VIEW
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="mx-auto text-center pb-5">
                    <img
                      alt="No prediction "
                      src={`${process.env.PUBLIC_URL}/assets/img/noPred.png`}
                    />
                    <br /> Oops ! Nothing For prediction
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}
